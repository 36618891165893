import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Navigation from "../components/Navigation/Navigation";
import Main from "../components/Main/Main";
import Content from "../components/Content/Content";
import Title from "../components/Title/Title";
import Page from "../components/Page/Page";
import Advertisement from "../components/Advertisement/Advertisement"
import ArticlePost from "../components/Article/ArticlePost";
import Footer from "../components/Footer/Footer";

const IndexPage = ({data}) => {
  const { title, bannerImage, postBody: { childMarkdownRemark: { html }}} = data.contentfulBlogPost
  
  return (
    <>
      <SEO title="Home" />
      <Layout>
        <Navigation />
        <Main>
          <Content>
            <Title title="about me" />
            <Page>
              <Advertisement />
              <ArticlePost
                  title={ title}
                  bannerImage={ bannerImage ? bannerImage.fluid.src : `` }
                  postBody={ html }
                />
              <Advertisement />
            </Page>
          </Content>
          <Footer />
        </Main>
      </Layout>
    </>
  );
};

export const query = graphql`{
  contentfulBlogPost(title: {eq: "Hello World"}) {
    title
    bannerImage {
      fluid(maxWidth: 800) {
        aspectRatio
        sizes
        src
        srcSet
      }
    }
    postBody {
      childMarkdownRemark {
        html
      }
    }
    published(formatString: "MMMM DD, YYYY")
  }
}`;

export default IndexPage;
